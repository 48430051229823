<template>
	<button @click="add" class="btn">Add</button>
</template>

<script>
export default {
	name: 'AddButton',
	methods: {
		add() {
			const newSession = {
				id: Math.floor(Math.random() * 100000),
				section: 'insert part',
				date: new Date().toLocaleDateString('pl'),
				timeStart: 'start',
				timeEnd: 'end',
				duration: '',
				isNew: true
			};

			this.$emit('add-click', newSession, true);
		}
	}
};
</script>

<style></style>
