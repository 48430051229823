<template>
	<header>
		<h1>Training Tracker</h1>
	</header>
</template>

<script>
import AddButton from './AddButton';

export default {
	name: 'Header',
	components: {
		AddButton
	},
	data() {
		return {
			section: '',
			date: '',
			timeStart: '',
			timeEnd: '',
			duration: ''
		};
	},
	methods: {
		add() {
			const newSession = {
				id: 2,
				section: 'klatka',
				date: new Date().toLocaleDateString('pl'),
				timeStart: '12:00',
				timeEnd: '14:00',
				duration: '2 godziny'
			};

			this.$emit('add-click', newSession);
		}
	}
};
</script>

<style></style>
