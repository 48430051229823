<template>
	<h2 style="font-weight: bold; color: red; text-align: center" v-if="isError">Error occured</h2>

	<table>
		<thead>
			<tr class="headers-row">
				<th>Partia</th>
				<th>data</th>
				<th>od</th>
				<th>do</th>
				<th>czas trwania</th>
				<th></th>
			</tr>
		</thead>

		<tbody>
			<tr :data-id="session.id" :key="session.id" v-for="session in sessions" class="row">
				<Session @isError="onError" :isNew="session.isNew" :session="session" />
			</tr>
		</tbody>
	</table>
</template>

<script>
import Session from './Session';
export default {
	name: 'Sessions',
	props: {
		sessions: Array,
		isNew: Boolean
	},
	data() {
		return {
			isError: false
		};
	},
	methods: {
		onError() {
			this.isError = true;
		}
	},
	components: {
		Session
	},
};
</script>

<style></style>
