<template>
	<td @click="editNewContent" ref="section" :contenteditable="isNew || editing">{{ session.section }}</td>
	<td @click="editNewContent" ref="date" :contenteditable="isNew || editing">{{ session.date }}</td>
	<td class="timeStart" @click="editNewContent" ref="timeStart" :contenteditable="isNew || editing">{{ session.timeStart }}</td>
	<td class="timeEnd" @click="editNewContent" ref="timeEnd" :contenteditable="isNew || editing">{{ session.timeEnd }}</td>
	<td class="duration" ref="duration">{{ session.duration }}</td>
	<td v-if="isNew">
		<img v-if="!loader && !hideSubmit" @click="submitNewSession" class="edit-icon" src="../assets/checkbox.svg" alt="" />
		<img v-if="hideSubmit && !loader" class="edit-icon" @click="toggleEditing" src="../assets/edit.svg" alt="" />
		<img v-if="editing" @click="updateSession($event)" class="edit-icon" src="../assets/checkbox.svg" alt="" />
		<Loader v-if="loader" />
	</td>
	<td v-else>
		<img v-if="!loader" class="edit-icon" @click="toggleEditing" src="../assets/edit.svg" alt="" />

		<img v-if="editing" @click="updateSession($event)" class="edit-icon" src="../assets/checkbox.svg" alt="" />
		<Loader v-if="loader" />
	</td>
</template>

<script>
import Loader from './Loader.vue';
export default {
	name: 'Session',
	data() {
		return {
			editing: null,
			loader: false,
			hideSubmit: false,
			isError: false
		};
	},
	components: {
		Loader
	},
	methods: {
		editNewContent(e) {
			if (e.target.hasAttribute('contenteditable')) {
				e.target.textContent = '';
			} else return;

			let observer = new MutationObserver(calculateDuration);
			observer.observe(document.querySelector('.timeEnd'), { characterData: true, subtree: true });
		},
		toggleEditing(e) {
			e.stopPropagation();
			if (this.editing == null) return (this.editing = true);
			else return (this.editing = null);
		},

		async submitNewSession() {
			try {
				this.hideSubmit = true;
				this.loader = true;
				const { section, date, timeStart, timeEnd, duration } = this.$refs;
				let path = `${window.location.protocol}//${window.location.hostname}/main`;
				let session = {
					id: Math.floor(Math.random() * 100000),
					section: section.textContent,
					date: date.textContent,
					timeStart: timeStart.textContent,
					timeEnd: timeEnd.textContent,
					duration: duration.textContent
				};
				await fetch(path, {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify(session)
				});
				this.loader = false;
			} catch (error) {
				this.$emit('isError', true);
			}
		},
		async updateSession(e) {
			try {
				this.editing = null;
				this.loader = true;
				let path = `${window.location.protocol}//${window.location.hostname}/editfile`;
				let id = e.target.parentElement.parentElement.dataset.id;
				const { section, date, timeStart, timeEnd, duration } = this.$refs;

				let session = {
					id,
					section: section.textContent,
					date: date.textContent,
					timeStart: timeStart.textContent,
					timeEnd: timeEnd.textContent,
					duration: duration.textContent
				};
				await fetch(path, {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify(session)
				});
				this.loader = false;
			} catch (error) {
				this.$emit('isError', true);
			}
		}
	},

	props: {
		session: Object,
		isNew: Boolean
	},
	emits: ['isError']
};
function calculateDuration() {
	let regexp = /\d:\d{1,2}/g;
	setTimeout(() => {
		if (
			document.querySelector('.timeEnd').textContent.match(regexp) &&
			document.querySelector('.timeStart').textContent.match(regexp)
		) {
			let dateNow = new Date(Date.now());
			let year = dateNow.getFullYear();
			let month = dateNow.getMonth();
			let day = dateNow.getDay();

			let timeStart = document.querySelector('.timeStart').textContent.split(':');
			let timeStartHour = timeStart[0];
			let timeStartMinutes = timeStart[1];

			let timeEnd = document.querySelector('.timeEnd').textContent.split(':');
			let timeEndHour = timeEnd[0];
			let timeEndMinutes = timeEnd[1];

			const d1 = new Date(year, month, day, timeStartHour, timeStartMinutes, 0, 0);
			const d2 = new Date(year, month, day, timeEndHour, timeEndMinutes, 0, 0);

			console.log((d2 - d1) / 1000 / 60 + ' ' + 'minutes');
			let difference = (d2 - d1) / 1000 / 60;

			let differenceHours = Math.floor(difference / 60);
			let differenceMinutes = difference % 60;

			document.querySelector('.duration').textContent =
				differenceHours === 0 ? `${differenceMinutes}m` : `${differenceHours}h ${differenceMinutes}m`;
		} else {
			setTimeout(() => {
				document.querySelector('.duration').textContent = 'invalid';
			}, 1000);
		}
	}, 1000);
}
</script>

<style scoped>
.spinner {
	margin: 0;
}
</style>
