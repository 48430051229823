<template>
	<div class="spinner">
  <div class="circle-border">
    <div class="circle-core"></div>
  </div>  
</div>
</template>

<script>
export default {
	name: 'Loader'
};
</script>

<style></style>
