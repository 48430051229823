<template>
	<Header @add-click="addSession" />

	<div class="container">
		<Add-button @add-click="addSession" />
		<Sessions :sessions="sessions" />
		<Loader v-if="loader && isError == false" />
		<h2 style="font-weight: bold; color: red; text-align: center" v-if="isError">Error occured</h2>
	</div>
</template>

<script>
import Header from './components/Header';
import AddButton from './components/AddButton';
import Sessions from './components/Sessions';
import Loader from './components/Loader';

export default {
	name: 'App',
	components: {
		Header,
		Sessions,
		AddButton,
		Loader
	},
	data() {
		return {
			sessions: [],
			loader: true,
			isError: false
		};
	},
	methods: {
		addSession(newSession) {
			this.sessions = [newSession, ...this.sessions];
		}
	},
	async created() {
		try {
			let path = `${window.location.protocol}//${window.location.hostname}/main`;
			const response = await fetch(path);
			const data = await response.json();
			this.loader = false;

			this.sessions = data.reverse();
		} catch (error) {
			this.isError = true;
		}
	}
};
</script>
